import React from "react";
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      reverse
      title="Intake(plus)"
      image={require("../../../../assets/op_stoeltjes_voor_kachel.jpg")}
      text={`Als jullie je huwelijksnood aan ons durven toevertrouwen nodigen we jullie van harte uit voor een zgn. intake(plus) - op afspraak - bij ons thuis in Zutphen. Je kunt ons daarvoor mailen of bellen (zie Contact). De intake(plus) is voor nadere kennismaking en om te luisteren naar jullie verhaal. N.a.v. deze persoonlijke eerste ontmoeting doen we aanbevelingen voor een mogelijk vervolg. Vooral voor degenen die van ver moeten komen is de intakeplus een logische start van een eventueel counselingtraject.\n\n### Minimale gift:\n\n* **Intake:** gratis (duur plm 2 à 2.5 uur);\n\n* **Intakeplus:** 	€ 90 - € 120 (intake, lunch, 2e sessie - totaalduur plm. 5 à 5.5 uur).
      `}
    />
  );
}
