import React from "react";
import AppTextImage from "../../../../components/app-text-image";

export default function TextArea() {
  return (
    <AppTextImage
      backgroundColor="rgb(255 229 202)"
      title="Vervolg counseling"
      image={require("../../../../assets/op_bank.jpg")}
      text={`De intake(plus) heeft meestal een vervolg in de vorm van counseling. Vaak blijkt er meer nodig te zijn voor een blijvend resultaat: herhaling, verdieping, groeiend vertrouwen, bewustere keuzes, beter inzicht. Het bespreken en doorleven van bepaalde thematiek, het samen uitwerken van opdrachten, het lezen van bepaalde boeken en - waar nodig - individuele gesprekken, zijn mogelijke onderdelen. Vervolgcounseling vindt plaats op een ochtend/middag of tijdens een eendaagse, met een frequentie van plm. 3 à 4 weken.\n\n### Minimale gift:\n\n* **Ochtend/middag:**	€ 55 - € 85 (duur plm. 2 à 2.5 uur).\n\n* **1-daagse:**	€ 120 - € 180 (incl. lunch, duur plm. 5 à 5.5 uur).`}
    />
  );
}
