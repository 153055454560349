import React from "react";
import AppTextImage from "../../../../components/app-text-image";
import AppTable from "../table";

export default function TextArea() {
  return (
    <AppTextImage
      title="ANBI-status"
      image={require("../../../../assets/samen_close_up.jpg")}
      text={`Stichting (V)écht ervoor! heeft een ANBI-status. Bekijk ons [beleidsplan](/beleidsplan-2022.pdf), [jaarverslag](/jaarverslag-2024.pdf) en de [jaarcijfers](/jaarrekening-2024.pdf). Onderstaande tabel geeft informatie over de stichting.`}
    >
      <AppTable />
    </AppTextImage>
  );
}
